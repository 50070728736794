<template>
  <div class="demo">
    <div class="msg">
      <!-- msg:{{ msg }} -->
      <div class="tip">
        温馨提示：请将条形码对准扫码口
      </div>
      <el-input ref="scanInput" v-model="handoverSeq" style="width: 158%;height: 60px;" @blur="inputblur()"></el-input>
      <el-button type="primary" @click.native="inputNum">确认</el-button>
    </div>
    <div v-if="isModalVisible">
      <div class="modal-overlay" @click="hideModal"></div>
      <div class="modal"></div>
    </div>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
  </div>
</template>
<script>
import qs from 'qs'
import { jsonPost, formPost } from '@/baseAPI'

export default {
  data() {
    return {
      msg: '',
      handoverSeq: '',
      isModalVisible: false,
      visible: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.scanInput.focus()
    })
  },
  created() {
    // 获取扫码的值
    // 方法二1
    this.$nextTick(() => {
      this.$watch(
        'handoverSeq',
        this.debounce(newQuery => {
          if (this.handoverSeq) {
            console.log('扫码获取的交接流水号', this.handoverSeq)
            // this.sendHandoverReceive(newQuery);//接口调用方法
            //  this.handoverSeq = "";
          }
        }, 1000)
      )
    })
    // // this.inputNum()
  },

  methods: {
    inputNum() {
      const that = this
      that.$router.push({
        path: '/regList',
        query: {
          patCardNo: this.handoverSeq
        }
      })
    },
    showModal() {
      this.isModalVisible = true
    },
    hideModal() {
      this.isModalVisible = false
    },

    home() {
      this.$router.push('/home')
    },

    // 初始获取焦点
    keyPress() {
      // nextTik 针对DOM 没有渲染出现Undefined问题
      this.$nextTick(() => {
        this.$refs.scanInput.focus()
      })
    },
    // 失去焦点
    inputblur() {
      let that = this
      // FireFox 和 IE 失去焦点，blur直接执行focus 不会生效，加个延时
      setTimeout(() => {
        that.$refs.scanInput.focus()
      }, 10)
    },
    //防抖处理
    debounce(func, delay) {
      let timer
      return function(...args) {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          func.apply(this, args)
        }, delay)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 100%;
  margin-left: 30%;
}

.demo {
  overflow: hidden;
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.el-input__inner {
  height: 50px !important;
}

.msg {
  margin-top: 50%;
  width: 40%;
  font-size: 45px;
  text-align: center;

  .tip {
    margin-left: 45%;
    margin-bottom: 22px;
    width: 150%;
    color: red;
    font-size: 45px;
  }
}

.el-button {
  padding-left: 10px;
  margin-left: 40%;
  font-size: 55px;
  width: 1200px;
  height: 180px;
  margin-top: 50px;
}

.ipt {
  padding-left: 10px;
  margin-left: 40%;
  font-size: 55px;
  width: 1200px;
  height: 180px;
  border: 2px solid #000;
  line-height: 180px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  z-index: 1000;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
